import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  HStack,
  Heading,
  Image,
  Box,
  Text,
  Input,
  Button,
  FormControl,
  Spinner,
  Flex,
  Link,
} from 'native-base';

import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSegment } from '../../hooks/useSegment';
import useEligibilityRequest from '../../api/onboarding/mutations/useEligibilityRequest';
import { TRACKING_ELIGIBILITY_CHECK } from '../OnboardingForm/constants';
import { useAuth } from '../../hooks';
import HeaderNav from '../HeaderNav/HeaderNav';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { FullBenefitsModal } from './FullBenefitsModal';
import { AffiliateType } from '../../models/affiliate';
import CardInput from './components/CardInput';

export const Eligibility = () => {
  const { page: pageView } = useSegment();
  const { user: authenticatedUser } = useAuth();
  const { state, search } = useLocation();
  const { affiliate } = useAffiliate({ affiliateId: state?.affiliate });
  const navigate = useNavigate();
  const { mutation } = useEligibilityRequest();

  const [formData, setData] = useState({
    firstName: state?.firstName,
    lastName: state?.lastName,
  });
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const [eligibilityDeniedMessage, setEligibilityDeniedMessage] =
    useState(null);
  const [showFullRelease, setShowFullRelease] = useState(false);

  const { userId, userEmail } = state || {};

  useEffect(() => {
    if (!userId) {
      navigate(`/${search}`);
    } else {
      pageView(TRACKING_ELIGIBILITY_CHECK);
    }

    // if the user is authenticated, meaning they just logged in
    // then we want to default the first and last name to their registered values
    if (authenticatedUser) {
      setData({
        firstName: authenticatedUser?.first_name,
        lastName: authenticatedUser?.last_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser?.first_name, authenticatedUser?.last_name, userId]);

  const dateRegex = /(\d{4})-(\d{2})-(\d{2})/;

  const validate = () => {
    if (!formData.firstName) {
      setErrors({ ...errors, error: 'First name is required' });
      return false;
    }
    if (!formData.lastName) {
      setErrors({ ...errors, error: 'Last name is required' });
      return false;
    }
    if (formData.memberId === undefined) {
      setErrors({ ...errors, error: 'Member ID is required' });
      return false;
    } else if (formData.dob === undefined) {
      setErrors({ ...errors, error: 'Date of birth is required' });
      return false;
    } else if (
      !!affiliate?.group_id_required &&
      formData.groupId === undefined
    ) {
      setErrors({ ...errors, error: 'Group ID is required' });
      return false;
    } else if (!formData.dob?.match(dateRegex)) {
      setErrors({
        ...errors,
        error: 'Date of birth must be in YYYY-MM-DD format',
      });
      return false;
    } else if (moment().diff(formData.dob, 'years') < 18) {
      setErrors({
        ...errors,
        error: 'Sorry, you must be 18+ in order to use Ayble.',
      });
      return false;
    } else if (
      !formData.auth_medical_release ||
      !formData.auth_payer_to_pay_ayble
    ) {
      setErrors({
        ...errors,
        error: 'You must agree to the authorizations',
      });
    } else {
      setErrors({});
      return true;
    }
  };

  const populateCardData = (data) => {
    const capitalizeValue = (value) =>
      value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

    const { memberNumber, groupNumber, memberName } = data.details;

    const memberId = memberNumber.value;
    const groupId = groupNumber.value;
    const memberNameFull = memberName.value;
    const memberNameParsed = memberNameFull.split(' ');

    if (memberId) setData({ ...formData, memberId });
    if (groupId) setData({ ...formData, groupId });
    if (memberNameParsed) {
      setData({
        ...formData,
        firstName: capitalizeValue(memberNameParsed[0]),
        lastName: capitalizeValue(
          memberNameParsed[memberNameParsed.length - 1]
        ),
      });
    }
  };

  const onSubmit = async () => {
    setEligibilityDeniedMessage(null);
    setFormHasChanged(false);

    if (validate()) {
      const {
        memberId,
        dob,
        auth_payer_to_pay_ayble,
        auth_medical_release,
        firstName,
        lastName,
        groupId,
        relationship,
      } = formData;
      try {
        const data = await mutation.mutateAsync({
          user_id: userId,
          member_id: memberId,
          date_of_birth: dob,
          user_email: userEmail,
          auth_payer_to_pay_ayble,
          auth_medical_release,
          first_name: firstName,
          last_name: lastName,
          group_id: groupId,
          relationship_to_subscriber: relationship || 'self',
        });
        if (data?.eligible) {
          navigate(`/thank-you?userId=${encodeURIComponent(userId)}`, {
            state: { eligible: true },
          });
        } else {
          setEligibilityDeniedMessage(data?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const navigateToPlans = () => {
    navigate(`/plans${search}`, {
      state: {
        affiliate: state?.affiliate,
        userId,
        userEmail,
        skippedEligibilityCheck: true,
      },
    });
  };

  return (
    <Box h={'100vh'}>
      <HeaderNav page={1} changePage={() => navigate(-1, { state })} />

      <HStack mt={10} mx="auto" w={['90%', '90%', '90%', '80%', '60%']}>
        <Box w={['100%', '100%', '100%', '50%']}>
          <Heading
            fontWeight="medium"
            fontSize="28px"
            textAlign={'left'}
            mb={18}
          >
            {affiliate?.affiliate_type === AffiliateType.healthPlan
              ? `Good news! Ayble may be in-network with ${affiliate?.name} or covered by your employer.`
              : `Ayble is a covered benefit for most ${affiliate?.name} employees. This means you can pay $0 out-of-pocket!`}
          </Heading>

          <Text mb={2} fontSize={'17px'}>
            Enter your information exactly as it appears on your health
            insurance card to see if you qualify for free* access to Ayble.
          </Text>
          <Text mb={2}>*Copays or coinsurance rates may apply.</Text>

          <CardInput onScanSuccess={populateCardData} userId={userId} />

          <FormControl
            mt={4}
            isDisabled={mutation.isLoading}
            isRequired
            isInvalid={'error' in errors}
          >
            {affiliate?.candid_eligibility && (
              <Box>
                <FormControl.Label>First Name</FormControl.Label>

                <Input
                  type="text"
                  maxWidth={'100%'}
                  value={formData.firstName}
                  onChangeText={(value) => {
                    setData({ ...formData, firstName: value });
                    setFormHasChanged(true);
                  }}
                />

                <FormControl.Label>Last Name</FormControl.Label>

                <Input
                  type="text"
                  maxWidth={'100%'}
                  value={formData.lastName}
                  onChangeText={(value) => {
                    setData({ ...formData, lastName: value });
                    setFormHasChanged(true);
                  }}
                />
              </Box>
            )}

            <FormControl.Label>Date of birth (YYYY-MM-DD)</FormControl.Label>
            <Input
              maxWidth={'100%'}
              type="text"
              value={formData.dob}
              onChangeText={(value) => {
                setData({ ...formData, dob: value });
                setFormHasChanged(true);
              }}
            />

            <FormControl.Label>Member ID</FormControl.Label>

            <Input
              type="text"
              maxWidth={'100%'}
              value={formData.memberId}
              onChangeText={(value) => {
                setData({ ...formData, memberId: value });
                setFormHasChanged(true);
              }}
            />

            {(affiliate?.candid_eligibility ||
              affiliate?.group_id_required) && (
              <>
                <FormControl.Label isRequired={!!affiliate.group_id_required}>
                  Group ID
                </FormControl.Label>
                <Input
                  maxWidth={'100%'}
                  type="input"
                  value={formData.groupId}
                  onChangeText={(value) => {
                    setData({ ...formData, groupId: value });
                    setFormHasChanged(true);
                  }}
                />
              </>
            )}

            <FormControl.Label>
              What is your relationship to the subscriber (insured)?
            </FormControl.Label>

            <Select
              isDisabled={mutation.isLoading}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 48,
                  backgroundColor: 'transparent',
                  marginBottom: 20,
                }),
              }}
              defaultValue={{ label: 'Self', value: 'self' }}
              menuPortalTarget={document.body}
              options={[
                { label: 'Self', value: 'self' },
                { label: 'Spouse', value: 'spouse' },
                { label: 'Child', value: 'child' },
                { label: 'Other', value: 'other' },
              ]}
              accessibilityLabel="Choose Relationship with subscriber"
              onChange={(selection) => {
                setData({ ...formData, relationship: selection.value });
                setFormHasChanged(true);
              }}
            />

            <Flex flexDir={'row'} alignItems={'flex-start'}>
              <input
                type="checkbox"
                name="auth_medical_release"
                id="auth_medical_release"
                checked={formData.auth_medical_release}
                onChange={(e) => {
                  setData({
                    ...formData,
                    auth_medical_release: e.target.checked,
                    auth_payer_to_pay_ayble: e.target.checked,
                  });
                  setFormHasChanged(true);
                }}
                data-testid="tos-auth_medical_release"
              />
              <Text ml={2}>
                I authorize the release of any medical or other information
                necessary to process claims to all my insurance carrier(s) and
                its authorized agents. I assign all payments, rights, and claims
                for reimbursement of claims, costs and expenses allowable under
                my insurance plan(s) directly to Ayble Health for services
                rendered. I understand that I am financially responsible for
                charges not covered by my insurance company. By checking this
                box I agree with these terms as well as the terms in the{' '}
                <Link
                  _text={{ textTransform: 'lowercase' }}
                  onPress={() => setShowFullRelease(true)}
                >
                  full benefits assignment
                </Link>
                {'.'}
              </Text>
            </Flex>

            {'error' in errors && (
              <Box>
                <FormControl.ErrorMessage mt={5}>
                  <Text fontSize={'md'}>{errors.error}</Text>
                </FormControl.ErrorMessage>
                <Text fontSize={'md'} mt={3} color="red.500">
                  Need help? Contact{' '}
                  <Link
                    _text={{ textTransform: 'none' }}
                    href={'mailto:support@ayblehealth.com'}
                  >
                    support@ayblehealth.com
                  </Link>
                  .
                </Text>
              </Box>
            )}
          </FormControl>

          {mutation.isError && (
            <Text color="red.500">
              Sorry, we are having trouble verifying your eligibility. Please
              try again later.
            </Text>
          )}

          {!!eligibilityDeniedMessage && (
            <Box>
              <Text my={3} lineHeight={20} color="red.500">
                {eligibilityDeniedMessage}
              </Text>
              <Text color="red.500">
                Need help? Contact{' '}
                <Link
                  _text={{ textTransform: 'none' }}
                  href={'mailto:support@ayblehealth.com'}
                >
                  support@ayblehealth.com
                </Link>
                .
              </Text>
            </Box>
          )}

          <HStack my={8} alignItems={'center'}>
            <Button
              isDisabled={
                mutation.isLoading ||
                !formHasChanged ||
                !formData.dob ||
                !formData.memberId ||
                !formData.auth_medical_release ||
                !formData.auth_payer_to_pay_ayble ||
                (!!affiliate?.group_id_required && !formData.groupId)
              }
              onPress={onSubmit}
              w="200px"
            >
              {mutation.isLoading ? (
                <Spinner color={'secondary.500'} />
              ) : (
                'Next'
              )}
            </Button>
            {!mutation.isLoading && (
              <Button w="200px" variant={'link'} onPress={navigateToPlans}>
                Skip this step
              </Button>
            )}
          </HStack>
        </Box>
        <Image
          alt={'two people high-fiving'}
          display={['none', 'none', 'none', 'block']}
          resizeMode="contain"
          w={'50%'}
          size={['xs', 'sm', 'md', 'lg', 'xl', 'xl']}
          source={{
            uri: '../../celebrate-phase2-completion.png',
          }}
        />
      </HStack>
      <FullBenefitsModal
        isOpen={showFullRelease}
        onClose={() => setShowFullRelease(false)}
      />
    </Box>
  );
};

export default Eligibility;
