import React from 'react';
import {
  VStack,
  Pressable,
  Text,
  Box,
  Heading,
  HStack,
  Center,
  Button,
} from 'native-base';
import DiagnosisSelectionForm from './DiagnosesSelectionForm';
import { FORM_ACTIONS } from '../../utils/formActions.js';
import { GIConditionsList } from './GIConditionsList';

function GiSymptomsInfo({ formData, dispatch, setParentPage }) {
  const isYesDiagnosedGiConditionActive =
    formData.hasUlcerativeColitis ||
    formData.hasCrohnsDisease ||
    formData.ibsConstipation ||
    formData.ibsMixed ||
    formData.ibsDiarrhea ||
    formData.ibsUnspecified ||
    formData.diagnosis_ids.length;

  const setYesDiagnosedGiCondition = () => {
    dispatch({
      type: FORM_ACTIONS.TOGGLE_FORM_DATA,
      fieldName: 'noDiagnosedGiCondition',
      payload: false,
    });

    dispatch({
      type: FORM_ACTIONS.TOGGLE_FORM_DATA,
      fieldName: 'yesDiagnosedGiCondition',
      payload: true,
    });
  };

  const setNoDiagnosedGiCondition = () => {
    dispatch({
      type: FORM_ACTIONS.TOGGLE_FORM_DATA,
      fieldName: 'noDiagnosedGiCondition',
      payload: true,
    });

    dispatch({
      type: FORM_ACTIONS.TOGGLE_FORM_DATA,
      fieldName: 'yesDiagnosedGiCondition',
      payload: false,
    });

    dispatch({
      type: FORM_ACTIONS.TOGGLE_FORM_DATA,
      fieldName: 'hasCrohnsDisease',
      payload: false,
    });

    dispatch({
      type: FORM_ACTIONS.TOGGLE_FORM_DATA,
      fieldName: 'hasUlcerativeColitis',
      payload: false,
    });
  };

  const handlePageChange = () => {
    setParentPage((currPage) => currPage + 1);
  };

  return (
    <VStack
      maxWidth={'100%'}
      space={1}
      className="personal-info-container"
      testID={'onboarding-gi-symptoms'}
    >
      <VStack space={1} className="header">
        <Heading fontSize={'3xl'}>
          Have you been diagnosed with a GI condition?
        </Heading>

        <Heading fontFamily="body" fontWeight={'300'} size="md" mb={2}>
          Ayble Health is clinically validated to help improve GI symptoms, whether or not you have a formal diagnosis.
        </Heading>
      </VStack>

      <Center maxWidth={'100%'} mb={10}>
        <HStack>
          <Pressable
            w={{ base: '100px', md: '200px' }}
            m=".5rem"
            onPress={() => {
              setYesDiagnosedGiCondition();
            }}
          >
            <Box
              bg={
                formData.yesDiagnosedGiCondition ? 'secondary.500' : 'light.50'
              }
              p="5"
              borderWidth="1"
              borderColor="muted.400"
              borderRadius="lg"
            >
              <Text
                m="auto"
                color={
                  formData.yesDiagnosedGiCondition
                    ? 'light.50'
                    : 'secondary.500'
                }
                fontWeight="medium"
                fontSize="xl"
                testID={'onboarding-gi-symptoms-yes-answer'}
              >
                Yes
              </Text>
            </Box>
          </Pressable>

          <Pressable
            w={{ base: '100px', md: '200px' }}
            m=".5rem"
            onPress={() => {
              setNoDiagnosedGiCondition();
            }}
          >
            <Box
              bg={
                formData.noDiagnosedGiCondition ? 'secondary.500' : 'light.50'
              }
              p="5"
              borderWidth="1"
              borderColor="muted.400"
              borderRadius="lg"
              style={{}}
            >
              <Text
                m="auto"
                color={
                  formData.noDiagnosedGiCondition ? 'light.50' : 'secondary.500'
                }
                fontWeight="medium"
                fontSize="xl"
                testID={'onboarding-gi-symptoms-no-answer'}
              >
                No
              </Text>
            </Box>
          </Pressable>
        </HStack>
      </Center>

      {formData.yesDiagnosedGiCondition && (
        <>
          <VStack maxWidth={'100%'} space={3} className="form-container">
            <VStack space={1} className="header">
              <Heading fontSize={'2xl'} mb={'2'}>
                What condition are you living with?
              </Heading>
            </VStack>
            <DiagnosisSelectionForm formData={formData} dispatch={dispatch} />
          </VStack>
          <GIConditionsList formData={formData} dispatch={dispatch} />
        </>
      )}

      {(formData.noDiagnosedGiCondition ||
        formData.yesDiagnosedGiCondition) && (
        <Center>
          <Button
            mt={6}
            space={1}
            block
            minWidth={'300px'}
            size={'lg'}
            isDisabled={
              formData.yesDiagnosedGiCondition &&
              !isYesDiagnosedGiConditionActive
            }
            onPress={() => handlePageChange()}
            testID={'onboarding-gi-symptoms-next-button'}
          >
            {'Next'}
          </Button>
        </Center>
      )}
    </VStack>
  );
}

export default GiSymptomsInfo;
