import { Heading, Text, useBreakpointValue, VStack } from 'native-base';
import styled from '@emotion/styled';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MobileInfoItems } from './MobileInfoItems';
import { ImageCarousel } from './ImageCarousel';
import { FaqLink } from './FaqLink';

const ANIMATED_CONTAINER_HEIGHT_PX = 1900;
const ANIMATION_HEIGHT_PX = 1100;

const StyledDiv = styled('div')<{
  reachedBottom: boolean;
  shouldHandleAnimation: boolean;
}>`
  height: ${(p) =>
    p.shouldHandleAnimation ? `${ANIMATED_CONTAINER_HEIGHT_PX}px` : null};

  &.sticky > div {
    position: fixed;
    top: 0;
    width: 100%;
  }
`;

export const Info = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  // True when the main container of this component reaches topScroll = 0
  // aka it is aligned with the top of the viewport
  const [isSticky, setIsSticky] = useState(false);
  // How much we scrolled internally over the section, once it became sticky
  const [pxScrolledFromTop, setPxScrolledFromTop] = useState<number>(0);
  // True when the users have finished the available scroll space for the animation
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  // The amount of pixels scrolled beyond the section bottom.
  // We need to add this amount to the section in order to scroll it up
  // manually once it needs to go away, to make room to the next section.
  const [pxFromBottom, setPxFromBottom] = useState<number>(ANIMATION_HEIGHT_PX);

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const handleScroll = useCallback(() => {
    if (!sectionRef?.current) return;

    const section = sectionRef.current;

    // Y coordinate of the top part of the container
    const refTopYCoordinate = section.getBoundingClientRect().top;
    // 0 means aligned to the top of the viewport
    const sticky = refTopYCoordinate <= 0;
    const sectionHeight = section.clientHeight;
    // We reach the bottom when we scroll down of the same amount of pixels
    // the height of the section has, or the available animation height is.
    // We use negative numbers because once the section becomes sticky,
    // when scrolling down, the amount becomes negative:
    //
    //                  +y      ↓
    // --- viewport ---  y = 0  ↓
    //                  -y      ↓
    //
    const reachedBottom =
      refTopYCoordinate <= -sectionHeight ||
      refTopYCoordinate <= -ANIMATION_HEIGHT_PX;

    const newPxFromBottom = reachedBottom
      ? refTopYCoordinate + ANIMATION_HEIGHT_PX
      : ANIMATION_HEIGHT_PX;

    // Tells us if we are scrolling over this section.
    // 100 is a threshold, we did not use 0 because we wanted to
    // start some visual effects a bit before the section reaches the viewport.
    const isScrollingInsideSection =
      refTopYCoordinate <= 100 && refTopYCoordinate >= -ANIMATION_HEIGHT_PX;
    if (isScrollingInsideSection) setPxScrolledFromTop(refTopYCoordinate * -1);

    // updates
    setIsSticky(sticky);
    setHasReachedBottom(reachedBottom);
    setPxFromBottom(newPxFromBottom);
  }, []);

  useLayoutEffect(() => {
    if (isMobile) return;

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const highlightedIndex = useMemo(() => {
    // roughly divided ANIMATION_HEIGHT_PX for the number of the bullets
    if (pxScrolledFromTop <= 0) return null;
    if (pxScrolledFromTop < 180) return 0;
    if (pxScrolledFromTop < 360) return 1;
    if (pxScrolledFromTop < 540) return 2;
    if (pxScrolledFromTop < 720) return 3;
    if (pxScrolledFromTop < 900) return 4;
    return 5;
  }, [pxScrolledFromTop]);

  return (
    <StyledDiv
      className={isSticky ? 'sticky' : ''}
      reachedBottom={hasReachedBottom}
      ref={sectionRef}
      shouldHandleAnimation={!isMobile}
    >
      <VStack
        alignItems={'center'}
        bgColor={'secondary.500'}
        p={{ base: 8, md: 12 }}
        py={12}
        // manually move this part up once
        // we scrolled the effects all way down
        style={!hasReachedBottom ? {} : { top: pxFromBottom }}
      >
        <Heading color={'white'} fontSize={['4xl', '6xl']} textAlign={'center'}>
          Whole-Person Virtual Care
        </Heading>

        <Text
          color={'white'}
          fontSize={['2xl', '3xl']}
          fontWeight={'normal'}
          pt={3}
          textAlign={'center'}
        >
          Everything your gut needs— join the Ayble experience!
        </Text>

        {isMobile ? (
          <MobileInfoItems />
        ) : (
          <ImageCarousel currentIndex={highlightedIndex} />
        )}

        <FaqLink />
      </VStack>
    </StyledDiv>
  );
};
