import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
  Image,
  HStack,
} from 'native-base';

const supportImg = '../../support-cropped.png';
const trackImg = '../../food-track.png';
const logImg = '../../girl-with-phone-cropped.png';
const resultImg = '../../results.png';

const cardConfig = {
  visit: {
    heading: [
      {
        text: 'One-On-One Visits With',
      },
      {
        text: 'Your Care Team',
        color: 'red.400',
      },
    ],
    image: {
      width: 159,
      height: 150,
      src: supportImg,
      alt: 'one-on-one visit image',
    },
    ctaText: 'Schedule a call',
    subText:
      'Work with an expert care team that specializes in GI nutrition, stress relief, and more.',
  },
  experts: {
    heading: [
      {
        text: 'Experts In All GI',
      },
      {
        text: 'Symptoms & Conditions',
        color: 'red.400',
      },
    ],
    image: {
      width: 247,
      height: 208,
      src: trackImg,
      alt: 'Discover image',
    },
    ctaText: 'Start your 2-day streak',
    subText:
      'Discover nutrition and psychology programs designed just for you to achieve lasting relief.',
  },
  manage: {
    heading: [
      {
        text: 'Log & Manage Symptoms',
      },
      {
        text: 'In The App',
        color: 'red.400',
      },
    ],
    image: {
      width: 249,
      height: 190,
      src: logImg,
      alt: 'Log and manage image',
      style: {
        pl: 3,
        pb: 6,
      },
    },
    ctaText: 'Say goodbye to tummy troubles',
    subText:
      'Log what you eat and how you feel to discover what triggers your symptoms.',
  },
  progress: {
    heading: [
      {
        text: 'Share Progress With Your',
      },
      {
        text: 'Primary Provider',
        color: 'red.400',
      },
    ],
    image: {
      width: 212,
      height: 182,
      src: resultImg,
      alt: 'Progress image',
    },
    ctaText: 'join now',
    subText:
      'Ayble Health doesn’t replace your primary GI provider — we support you in-between visits with everyday symptom management.',
  },
};

const Card = ({
  isMobile,
  configKey,
  handleCta,
  grow,
}: {
  isMobile?: boolean;
  configKey: keyof typeof cardConfig;
  handleCta: (configKey: keyof typeof cardConfig) => void;
  grow?: boolean;
}) => {
  const { heading, image, ctaText, subText } = cardConfig[configKey];

  return (
    <Box
      px={{ base: 4, sm: 8, md: 16, lg: 12 }}
      py={{ base: 8, md: 12 }}
      bgColor={'muted.100'}
      rounded={12}
      my={{ base: 2, lg: 4 }}
      mx={{ base: 5, lg: 0 }}
      flexGrow={grow ? 1 : undefined}
    >
      {heading.map(({ color, text }: { text: string; color?: string }) => (
        <Heading fontSize={{ base: '2xl', sm: '3xl', xl: '4xl' }}>
          <Text color={color}>{text}</Text>{' '}
        </Heading>
      ))}
      <Text
        mt={8}
        mb={4}
        px={{ base: 0, md: 24, lg: 4 }}
        fontSize={'lg'}
        textAlign={'center'}
      >
        {subText}
      </Text>
      <Box
        w={'100%'}
        alignItems={'center'}
        mb={'auto'}
        py={2}
        {...('style' in image ? image.style : {})}
      >
        <Image
          alt={image.alt}
          display={'block'}
          resizeMode={'contain'}
          source={{ uri: image.src }}
          height={isMobile ? image.height * 0.75 : image.height}
          width={isMobile ? image.width * 0.75 : image.width}
        />
      </Box>
      <Button
        w={'100%'}
        maxW={'343px'}
        mt={'auto'}
        mx={'auto'}
        size={isMobile ? 'sm' : 'md'}
        onPress={() => handleCta(configKey)}
      >
        {ctaText}
      </Button>
    </Box>
  );
};

export const Guidance = ({
  onStartOnboarding,
}: {
  onStartOnboarding: (eventStr?: string) => void;
}) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const onCtaClick = (configKey: keyof typeof cardConfig) => {
    const trackingEventStr = `landing_page_${configKey}_cta_clicked`;
    onStartOnboarding(trackingEventStr);
  };

  return (
    <Center backgroundColor={'secondary.500'}>
      <VStack w={'100%'} m={0} mt={8} mx={5} maxW={'1410px'} pb={16}>
        <Heading
          color={'white'}
          fontWeight={'normal'}
          letterSpacing={'0px'}
          size={'xl'}
          py={6}
          mx={5}
        >
          GI Nutrition and Psychological Support, Personalized To You
        </Heading>

        {isMobile ? (
          <VStack>
            <Card handleCta={onCtaClick} isMobile configKey={'visit'} />
            <Card handleCta={onCtaClick} isMobile configKey={'experts'} />
            <Card handleCta={onCtaClick} isMobile configKey={'manage'} />
            <Card handleCta={onCtaClick} isMobile configKey={'progress'} />
          </VStack>
        ) : (
          <HStack>
            <VStack w={'50%'} flex={1} ml={5} mr={4}>
              <Card handleCta={onCtaClick} configKey={'visit'} />
              <Card handleCta={onCtaClick} configKey={'manage'} grow />
            </VStack>
            <VStack w={'50%'} flex={1} mr={5} ml={4}>
              <Card handleCta={onCtaClick} configKey={'experts'} grow />
              <Card handleCta={onCtaClick} configKey={'progress'} />
            </VStack>
          </HStack>
        )}
      </VStack>
    </Center>
  );
};
