import { useState, useEffect } from 'react';
import { Flex, Input, Spinner } from 'native-base';
import {
  CardScanView,
  CardScanModel,
} from '@cardscan.ai/insurance-cardscan-react';
import CardInputButton from './CardInputButton';
import { useCardScanAuth } from '../../../api/cardscan/useCardScanAuth';

const scannerImage = '../../card-scanner.png';

export const CardInput = ({ onScanSuccess, userId }) => {
  const [inputView, setInputView] = useState<'scan' | 'upload' | null>(null);
  const { authObject, isLoading } = useCardScanAuth({ userId });
  const { REACT_APP_ENV_LABEL = 'unknown' } = process.env;
  const islive = REACT_APP_ENV_LABEL === 'production'

  useEffect(() => {
    CardScanModel.warm();
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Flex flexDir={'row'} my={4} justifyContent={'center'}>
        <CardInputButton
          text={'Scan insurance card with camera'}
          image={scannerImage}
          isActive={inputView === 'scan'}
          onPress={() => setInputView((v) => (v === 'scan' ? null : 'scan'))}
        />
        {/*Add back for uploader button*/}
        {/* <CardInputButton
                  isActive={inputView === 'upload'}
                  text={'Upload Images of your insurance card'}
                  onPress={() =>
                    setInputView((v) => (v === 'upload' ? 'null' : 'upload'))
                  }
                /> */}
      </Flex>
      {inputView === 'scan' && (
        <CardScanView
          live={islive}
          backsideSupport
          fullScreen={false}
          sessionToken={authObject?.Token}
          cameraPermissionModalConfig={{ enabled: false }}
          onCancel={() => setInputView(null)}
          onSuccess={(data) => {
            onScanSuccess(data);
            setTimeout(() => {
              setInputView(null);
            }, 500);
          }}
          onError={() => console.error('error')}
        />
      )}

      {inputView === 'upload' && <Input />}
    </>
  );
};

export default CardInput;
