import styled from '@emotion/styled';
import { Heading, ITheme, Text, Box } from 'native-base';
import { Headline } from './Headline';
import { Drophead } from './Drophead';
import { Logos } from './Logos';
import { Info } from './Info';
import { Claim } from './Claim';
import { Footer } from './Footer';
import { Guidance } from './Guidance';

const Link = styled('a')<{ theme?: ITheme }>`
  color: #f8623f;
  transition: color linear 200ms;

  &:hover {
    color: ${(p) => p.theme.colors.red['500']};
  }
`;

type Props = {
  onGetStartedButtonClick: () => void;
};

export const Body = ({ onGetStartedButtonClick }: Props) => {
  return (
    <>
      <Headline onGetStartedButtonClick={onGetStartedButtonClick} />

      <Guidance onStartOnboarding={onGetStartedButtonClick} />

      <Heading pt={12} pb={6} size={'xl'}>
        Curious if Ayble is right for you?{' '}
      </Heading>
      <Box pb={12} textAlign={'center'}>
        <Text fontSize={'xl'} color={'black'} mb={1}>
          Email us at{' '}
          <Link href="mailto:support@ayblehealth.com">
            support@ayblehealth.com
          </Link>
        </Text>

        <Text fontSize={'xl'} color={'black'}>
          Or call us at <Link href="tel:8574169299">857-416-9299</Link>
        </Text>
      </Box>

      <Drophead onGetStartedButtonClick={onGetStartedButtonClick} />
      <Logos />
      <Info />
      <Claim onButtonClick={onGetStartedButtonClick} />
      <Footer />
    </>
  );
};
