import React from 'react';
import {
  VStack,
  Box,
  Heading,
  Text,
  Image,
  Flex,
  useBreakpointValue,
  Button,
} from 'native-base';

const title = 'You’re in the right place!';
const subtitle =
  'Unlock digestive care with the support you need.';

const secondaryTitle = 'Still curious how we can help?';
const secondarySubtitle = 'Here is our (gut-friendly 😉) bread & butter:';

const bullets = [
  'Chat to your expert digestive care team anytime, from anywhere',
  'Find your trigger foods and build a diet that works for you',
  'Break the stress-symptom cycle with powerful guided meditations and practices',
  'Track your gut health symptoms and watch them change over time',
  'Remove the guesswork at the store with our grocery-finding tool and barcode scanner',
  'Learn from educational content written by GI experts and clinicians',
];
const heroImg = './../girl-favorite-on-phone.png';
const supportImg = '../../support-cropped.png';
const healthCompanionImg = '../../girl-with-phone-cropped.png';

const circleConfig = (isMobile) => [
  {
    image: supportImg,
    imageWidth: isMobile ? '50px' : '70px',
    imageHeight: isMobile ? '50px' : '70px',
    title: 'Dedicated GI Care Team',
    subtitle: 'Unlimited virtual visits whenever you need us',
    circleStyles: {
      background:
        'linear-gradient(0deg, #7F92A4 0%, #B0C0D7 40%, #D5E2FC 100%)',
      padding: isMobile ? '35px' : '45px',
      marginRight: isMobile ? 'auto' : '-50px',
    },
  },
  {
    image: healthCompanionImg,
    imageWidth: isMobile ? '50px' : '70px',
    imageHeight: isMobile ? '50px' : '70px',
    title: 'Personalized Gut Health Companion',
    subtitle: 'Clinically proven programs and tech to reduce symptoms',
    circleStyles: {
      background:
        'linear-gradient(0deg, #D5E0F6 0%, #E5D2AB 40%, #F1CA86 100%)',
      marginTop: isMobile ? '-150px' : 'auto',
      marginLeft: isMobile ? '185px' : 'auto',
      padding: isMobile ? '20px' : '30px',
    },
  },
];

// 5_see_if_eligible_page
const TransitionGoal = ({ onNext }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <VStack alignItems={'center'} space={3} testID={'transitional-goal'}>
      <Heading fontSize={'3xl'} mb={'0'} maxW={!isMobile ? '60%' : 'auto'}>
        {title}
      </Heading>

      <Heading
        fontFamily="body"
        fontWeight={'300'}
        size={'md'}
        mb={10}
        w={isMobile ? '100%' : '78%'}
      >
        {subtitle}
      </Heading>

      <Flex
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'center'}
        gap={isMobile ? 5 : 'auto'}
      >
        {circleConfig(isMobile).map((circle) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: isMobile ? 'auto' : 'center',
                gap: isMobile ? '5px' : '10px',
                alignItems: 'center',
                boxSizing: 'border-box',
                textAlign: 'center',
                width: isMobile ? '220px' : '275px',
                height: isMobile ? '220px' : '275px',
                borderRadius: '50%',
                ...circle.circleStyles,
              }}
            >
              <Image
                src={circle.image}
                width={isMobile ? '60px' : '70px'}
                height={isMobile ? '60px' : '70px'}
              />

              <Heading
                fontSize={isMobile ? '20' : '24'}
                fontWeight={600}
                fontFamily={'body'}
              >
                {circle.title}
              </Heading>
              <Text fontSize={isMobile ? '14' : '16'} color={'black'}>
                {circle.subtitle}
              </Text>
            </div>
          );
        })}
      </Flex>

      <Button my={10} onPress={onNext}>
        Find Lasting GI Relief
      </Button>

      <VStack>
        <Box my={isMobile ? 0 : 10}>
          <Heading fontSize={'xl'} fontFamily={'body'} fontWeight={700}>
            {secondaryTitle}
          </Heading>
          <Heading fontFamily={'body'} fontSize={'xl'} fontWeight={400} mt={2}>
            {secondarySubtitle}
          </Heading>
        </Box>
        <Flex direction="row" px={isMobile ? 0 : 5} mb={10}>
          <ul>
            {bullets.map((bullet, index) => (
              <Box m={5}>
                <li key={index}>
                  <Text fontFamily="body" fontSize={'lg'} fontWeight={400}>
                    {bullet}
                  </Text>
                </li>
              </Box>
            ))}
          </ul>
          <Image
            display={isMobile ? 'none' : 'block'}
            src={heroImg}
            w={'175px'}
            h={'409px'}
          />
        </Flex>
      </VStack>
    </VStack>
  );
};

export default TransitionGoal;
